//----QUASAR
import Notify from 'quasar/src/plugins/Notify.js';;

export default class AlertsClass {
  alertOf(message, color = "red-9") {
    Notify.setDefaults({
      position: "top",
      timeout: 5000,
      textColor: "white",
      color: color,
    });

    Notify.create(message);
  }
}
