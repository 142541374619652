<template>
  <div
    class="flex column items-end justify-evenly full-height q-px-md"
  >
    <div class="full-width q-pa-sm text-center">
      <h4>Você finalizou esse teste</h4>
      <h6>Sua nota foi {{score}}</h6>

      <q-btn
        color="default-pink"
        text-color="white"
        no-caps
        unelevated
        dense
        label="Voltar"
        v-close-popup
        :to="{
          name: 'lighting-test-list',
          query: {},
        }"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "FinishedExam",
  props:{
    isShowScore:Boolean,
    score:Number
  }
};
</script>


<style lang="scss" scoped>

</style>
