<template>
  <div class="tab-content">
    <div class="col-12">
      <div class="col-12" v-if="showContentTop">
        <slot name="cards-tabs"></slot>
      </div>
      <div class="q-pa-md row col-12">
        <div class="col-1">
          <q-btn
            flat
            round
            color="default-pink"
            @click="scrollToLeft"
            type="button"
            icon="chevron_left"
          />
        </div>

        <div class="tab col-10">
          <button
            class="tablinks"
            v-for="item in labelTab"
            :key="item.question.id"
            :id="'btn-' + item.question.id"
            type="button"
            @click="selectionTab(item.question.id)"
          >
            {{ item.question.id }}
          </button>
          <button class="add-new-tab" type="button" v-if="btnNewTab" @click="addNewTab">
            +
          </button>
          
        </div>
        <div class="col-1">
          <q-btn
            flat
            round
            color="default-pink"
            @click="scrollToRight"
            icon="chevron_right"
          />
        </div>
      </div>
      <div class="col-12" v-if="showContentButton">
        <slot name="cards-tabs"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DefaultTabs",
  props: {
    tab: Number,
    labelTab: Array,
    initialTab: {
      type: Number,
      default: 0,
    },
    showContentTop: {
      type: Boolean,
      default: false,
    },
    btnNewTab: {
      type: Boolean,
      default: true,
    },
    checkedTabs: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      showContentButton: true,
    };
  },
  watch: {
    checkedTabs: {
      deep: true,
      handler(checkes) {
        this.handleCheckBtn(checkes)
      },
    },
  },
  methods: {
    handleCheckBtn(checkes){
      if (checkes != "") {
          checkes.map((check) => {
            if (this.labelTab.length >= check + 1) {
              this.selectionTab(check + 1);
            }
            document.getElementById("btn-" + check).className += " check";
          });
        }
    },
    selectionTab(value) {
      var i, tabcontent, tablinks;

      tabcontent = document.getElementsByClassName("content-tabs");
      for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
      }
      tablinks = document.getElementsByClassName("tablinks");
      for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(" active", "");
      }
      document.getElementById(value).style.display = "block";
      document.getElementById("btn-" + value).className += " active";
      //event.target.className += " active";
    },
    scrollToLeft() {
      document
        .querySelector(".tab")
        .scrollTo(document.querySelector(".tab").scrollLeft + -50, 0);
    },
    scrollToRight() {
      document
        .querySelector(".tab")
        .scrollTo(document.querySelector(".tab").scrollLeft + 50, 0);
    },
    addNewTab() {
      this.$emit("addNewTab");
    },
  },
  mounted() {
    
   this. handleCheckBtn(this.checkedTabs);

    if (this.initialTab != 0) {
      this.selectionTab(this.initialTab);
    }

    if (this.showContentTop == true) {
      this.showContentButton = false;
    } else {
      this.showContentButton = true;
    }
  },
};
</script>

<style lang="scss">
.tab {
  background-color: #ffffff;
  overflow-x: hidden;
  white-space: nowrap;
}

.tab button {
  height: 40px;
  width: 40px;
  background-color: inherit;
  border-radius: 50%;
  border: 1px solid var(--q-primary, #fe4e64);
  cursor: pointer;
  margin: 0 1%;
  transition: 0.3s;
  font-size: 15px;
}

.tab button:hover {
  background-color: #ffadb8e8;
}

.tab button.check {
  background: #fdfdfd;
  border: 2px solid #489b88;
  color: #489b88;
}

.tab button.active {
  background-color: var(--q-primary, #fe4e64);
  color: #ffffff;
}

.add-new-tab {
  height: 40px;
  width: 40px;
  background-color: inherit;
  border-radius: 50%;
  border: 1px solid #ccc;
}

.add-new-tab {
  background: #e5e5e5 !important;
  opacity: 0.45 !important;
  border: none;
}
</style>