<template>
  <!-- color="default-pink" -->
  <!-- :text-color="outline == true ? 'default-pink' : 'white'" -->
  <q-btn
    :class="[outline ? 'btn-content-outline' : 'btn-content']"
    rounded
    no-caps
    :outline="outline"
    unelevated
    :to="{ name: route, query: query }"
    @click="$emit('clickbtn')"
  >
    <span v-show="showIcon">
      <q-img
        width="18px"
        :src="require(`@/assets/icons/${icon}.svg`)"
        spinner-color="white"
      />
    </span>
    <p style="margin: 0px 10px">{{ titleButton }}</p>
  </q-btn>
</template>

<script>
export default {
  name: "DefaultBtn",
  props: {
    titleButton: String,
    showIcon: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
      default: "plus",
    },
    route: {
      type: String,
      default: "",
    },
    query: {
      type: Object,
      default: {},
    },
    outline: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-content {
  background: var(--q-secundary);
  color: white;
  box-shadow: 3px 10px 15px #00000033;
  padding: 8px 15px;

  ::before {
    box-shadow: none;
  }
}

.btn-content-outline{
  color: var(--q-secundary);
}

button span {
  background: #ffffff33;
  margin-right: 5px;
  padding: 2px;
  border-radius: 50%;
  width: 25px;
}

button p {
  font-size: 0.8rem;
  font-weight: 600;
  margin: 0 !important;
}
</style>
