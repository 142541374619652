import http from "../http-common";

export default class PostDataServices {
  async getAll(pageNumber, pageSize, isFavorite = false) {
    return await http.get(
      `v1/post?PageNumber=${pageNumber}&PageSize=${pageSize}&favorite=${isFavorite}`
    );
  }

  async getById(postId) {
    return await http.get(`v1/post/${postId}`);
  }

  async getViewsById(postId) {
    return await http.post(`v1/post/${postId}`);
  }

  async updatePost(postId, data, FunctionLoading) {
    return await http.put(`v1/post/${postId}`, data, {
      onUploadProgress: (event) => {
        let progress = Math.round((event.loaded * 100) / event.total);
        FunctionLoading(progress);
      },
    });
  }

  async getPostsByTitle(pageNumber, pageSize, title) {
    return await http.get(
      `v1/post/search?PageNumber=${pageNumber}&PageSize=${pageSize}&title=${title}`
    );
  }

  async getAllCategories(companyId) {
    return await http.get(`v1/post/post-categories?companyId=${companyId}`);
  }

  async getCommentsByPost(postId, pageNumber, pageSize) {
    return await http.get(
      `v1/post/${postId}/more-comments?PageNumber=${pageNumber}&PageSize=${pageSize}`
    );
  }

  async getCommentsAwnsers(postId, commentId) {
    return await http.get(`v1/post/${postId}/Parent/${commentId}/Comments`);
  }

  async addComment(postId, commentId, data) {
    return await http.post(
      `v1/post/${postId}/Comment/${commentId}/Comments`,
      data
    );
  }

  async addPost(data, FunctionLoading) {
    return await http.post(`v1/post`, data, {
      onUploadProgress: (event) => {
        let progress = Math.round((event.loaded * 100) / event.total);
        FunctionLoading(progress);
      },
    });
  }

  async disabled(postId) {
    return await http.delete(`v1/post/${postId}`);
  }

  async disabledComment(postId, commentId) {
    return await http.delete(`v1/post/${postId}/Comment/${commentId}/Comments`);
  }

  async editComment(postId, commentId, data) {
    return await http.put(
      `/v1/post/${postId}/Comment/${commentId}/Comments`,
      data
    );
  }

  async addPostFavorite(postId, favorite) {
    return await http.post(`/v1/post/${postId}/favorite-post/${favorite}`);
  }

  async updateFixePost(postId, data) {
    return await http.put(`/v1/post/${postId}/fixed-post`, data);
  }

  async addCategories(data) {
    return http.post(`v1/post/post-categories-add`, data);
  }

  async createSurvey(data) {
    return http.post(`v1/post/add-poll`, data);
  }

  async updateCategories(categoryId, data) {
    return http.put(
      `v1/post/post-categories-update?categoryId=${categoryId}`,
      data
    );
  }

  async removeCategories(categoryId) {
    return http.delete(
      `v1/post/post-categories-disabled?categoryId=${categoryId}`
    );
  }

  async likeOrDeslike(postId, data) {
    return await http.post(`v1/post/${postId}/Like-Or-Deslike`, data);
  }

  async postHistory(data) {
    return await http.post(`v1/post/History-Post`, data);
  }

  async addLightningTest(data) {
    return await http.post(`v1/post/add-lightningtest`, data);
  }

  async getAllLightningTest(data) {
    return await http.get(`v1/post/all-lightningtest`, data);
  }

  async getLightningTestById(lightningTestId) {
    return await http.get(`v1/post/lightningtest/${lightningTestId}/data`);
  }

  async getLightningTestEditById(lightningTestId) {
    return await http.get(`v1/post/lightningtest/${lightningTestId}`);
  }

  async editLightningTestById(lightningTestId, data) {
    return await http.put(
      `v1/post/update-lightningtest/${lightningTestId}`,
      data
    );
  }
  async deleteLightningTestById(lightningTestId) {
    return await http.put(
      `v1/post/delete-lightningtest?lightningTestId=${lightningTestId}`
    );
  }

  async lightningTestStart(lightningTestId) {
    return await http.post(`v1/post/lightningtest/${lightningTestId}/start`);
  }

  async lightningTestAnswer(lightningTestId, answerId, data) {
    return await http.post(
      `v1/post/lightningtest/${lightningTestId}/answer/${answerId}`,
      data
    );
  }

  async lightningTestScore(lightningTestId) {
    return await http.get(`v1/post/lightningtest/${lightningTestId}/score`);
  }

  async historyLightningTest(data) {
    return await http.post(`v1/post/history-test`, data);
  }

  async addViewPost(postId) {
    return await http.post(`v1/post/view-post?postId=${postId}`);
  }

  async insertPollResponse(answers_id, post_id) {
    return await http.post(
      `v1/post/insert-poll-response?answers_id=${answers_id}&post_id=${post_id}`
    );
  }

  async getAllPoll() {
    return await http.get(`v1/post/get-all-poll`);
  }

  async getOptions(id) {
    return await http.get(`v1/post/get-options?post_id=${id}
    `);
  }
  async getInformationSurvey(id) {
    return await http.get(`v1/post/get-poll-information?post_id=${id}
    `);
  }
}
