<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.0625 10.7188C6.21875 10.7188 6 11 5 11C4 11 3.78125 10.7188 2.90625 10.7188C0.65625 10.7188 0 12.5938 0 13.5938V14.75C0 15.4688 0.53125 16 1.25 16H8.75C9.4375 16 10 15.4688 10 14.75V13.5938C10 12.25 9.03125 10.7188 7.0625 10.7188ZM8.5 14.5H1.5V13.5938C1.5 13.1562 1.75 12.2188 2.90625 12.2188C3.4375 12.2188 3.8125 12.5 5 12.5C6.15625 12.5 6.53125 12.2188 7.0625 12.2188C8.21875 12.2188 8.5 13.1562 8.5 13.5938V14.5ZM5 10C6.65625 10 8 8.6875 8 7C8 5.34375 6.65625 4 5 4C3.3125 4 2 5.34375 2 7C2 8.6875 3.3125 10 5 10ZM5 5.5C5.8125 5.5 6.5 6.1875 6.5 7C6.5 7.84375 5.8125 8.5 5 8.5C4.15625 8.5 3.5 7.84375 3.5 7C3.5 6.1875 4.15625 5.5 5 5.5ZM18.5 0H6.5C5.65625 0 5 0.71875 5 1.5625V3C5.28125 3 6 3.125 6.5 3.3125V1.5H18.5V11.5H17V10C17 9.46875 16.5312 9 16 9H12C11.4375 9 11 9.46875 11 10V13H18.5C19.3125 13 20 12.3125 20 11.4688V1.5625C20 0.71875 19.3125 0 18.5 0ZM15.5 11.5H12.5V10.5H15.5V11.5Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: "Teacher",
  props: {
    width: {
      type: Number,
      default: 20,
    },
    height: {
      type: Number,
      default: 16,
    },
    color: {
      default: {
        default: "var(--q-primary)",
      },
    },
  },
  setup() {},
};
</script>

<style lang="scss" scoped></style>
