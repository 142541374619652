<template>
  <div class="exam-layout full-width full-height">
    <LightingTest :examdata="Examdata" />
    <main class="exam-content q-pa-sm">
      <FinishedExam
        v-if="isShowConcluded"
        :score="score"
      />
      <OrientationExam
        v-else-if="isShowOrientation"
        :isEndExam="isEndExam"
        :isStarted="isStarted"
        :isAllQuestionsAnswered="isAllQuestionsAnswered"
        @handleExam="()=>handleExam()"
        @finishExam="()=>finishExam()"
      />
      <TabDefault
        v-else
        :tab="1"
        :label-tab="Examdata.question"
        :btn-new-tab="false"
        :showContentTop="true"
        :initialTab="1"
        :checkedTabs="checkedQuestions"
      >
        <template v-slot:cards-tabs>
          <div
            class="content-tabs"
            v-for="(item, questionPosition) in Examdata.question"
            :key="item.question.id"
            :id="item.question.id"
          >
            <div class="questioncontent q-pa-md">
              <h3>{{ item.question.title }}</h3>
              <p>{{ item.question.description_question }}</p>
              <div
                class="card-question"
                v-if="item.question.option_type_id == 1"
              >
                <div
                  v-for="option in item.question.options"
                  :key="option.questionOptionId"
                  :class="[
                    option.checks_for_student
                      ? 'card-option-selected'
                      : 'card-option',
                    'row items-center q-pa-sm',
                  ]"
                  @click="
                    selectAnswer(questionPosition, option.questionOptionId)
                  "
                >
                  <h6>{{ option.letter }}</h6>
                  <p>{{ option.description_option }}</p>
                </div>
              </div>
              <div v-else class="card-question">
                <q-checkbox
                  :class="[
                    option.checks_for_student
                      ? 'card-option-selected'
                      : 'card-option',
                    'row items-center q-pa-sm',
                  ]"
                  v-for="option in item.question.options"
                  :key="option.questionOptionId"
                  dense
                  @click="selectMultiAnswer(questionPosition)"
                  v-model="option.checks_for_student"
                  :false-value="false"
                  :val="option.checks_for_student"
                  :label="option.description_option"
                  color="teal"
                >
                </q-checkbox>
              </div>
              <div class="flex justify-end">
                <DefaultBtn
                  :showIcon="false"
                  titleButton="Confirmar resposta"
                  @click="(event) => confirmAnswer(event, questionPosition)"
                />
              </div>
            </div>
          </div>
        </template>
      </TabDefault>
      <q-inner-loading
        :showing="loading"
        label="Por favor aguarde..."
        label-class="text-teal"
        label-style="font-size: 1.1em"
      />
    </main>
  </div>
</template>

<script>
//----IMAGES
import teacherIcon from "@/assets/icons/teacher.svg";

//----COMPONENTS
import BadgeType from "@/components/BadgeType.vue";
import TabDefault from "@/components/tabs/DefaultTabs.vue";
import DefaultBtn from "@/components/shared/buttons/DefaultBtn.vue";
import LightingTest from "@/components/exam/menu/LightingAside.vue";
import FinishedExam from "@/components/exam/menu/FinishedExam.vue";
import OrientationExam from "@/components/exam/menu/OrientationExam.vue";


//----SERVICES
import PostDataServices from "@/services/PostDataServices";

//----UTILS
import AlertsClass from "@/utils/Notifications/AlertsClass";

//----QUASAR
import { useQuasar } from "quasar";

//----VUEJS
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";

export default {
  name: "Exam",
  components: {
    BadgeType,
    TabDefault,
    DefaultBtn,
    LightingTest,
    FinishedExam,
    OrientationExam,
  },
  setup() {
    //----QUASAR
    const $q = useQuasar();

    //----ROUTER
    const route = new useRoute();

    //----SERVICES
    const postDataServices = new PostDataServices();

    //----CONSTANTS
    const refFileInput = ref(null);
    const refTabs = ref(1);
    const ligthingTestId = route.query.id;
    const isConcluded = route.query.concluded;

    //----UTILS
    const Alerts = new AlertsClass();

    //----VARIABLES
    let isDescription = ref(true);
    let isOrientation = ref(true);
    let isShowOrientation = ref(true);
    let isShowConcluded = ref(false);
    let isStarted = ref(false);
    let isShowScore = ref(true);
    let isAllQuestionsAnswered = ref(false);
    let isEndExam = ref(false);
    let score = ref(0);
    //let countDownDisplay = ref(["00", "00", "00"]);
    let Examdata = ref({
      title: "",
      tutor: "",
      points: 0,
      timer: 0,
      number_questions: 0,
      orientations: "",
      question: [],
    });
    let checkedQuestions = ref([]);
    let countQuestion = ref(1);

    //let isConcluded = route.query.concluded;

    let loading = ref(false);
    let answerIdInPage = ref(null);

    onMounted(async () => {
      if(isConcluded == 'true'){
        await finishExam();
      }
      await _getQuiz();
      // _getSaveData();
    });

    async function _getQuiz() {
      postDataServices
        .getLightningTestById(ligthingTestId)
        .then((response) => {
          Examdata.value.title = response.data.title;
          Examdata.value.tutor = response.data.tutor;
          Examdata.value.orientations = response.data.description;
          Examdata.value.points = response.data.points;
          Examdata.value.timer = response.data.timer;

          questions.value.start_date = date.formatDate(
            response.data.start_date,
            "YYYY-MM-DD"
          );
          questions.value.end_date = date.formatDate(
            response.data.end_date,
            "YYYY-MM-DD"
          );
        })
        .catch((error) => {
          loading.value = false;
        });
      // loading.value = false;
    }

    async function _startQuiz() {
      postDataServices
        .lightningTestStart(ligthingTestId)
        .then(async (response) => {
          let quantityQuestionAnswered = 0;
          if (response.status == 200) {
            // response.data.forEach((quiz) => {
            response.data.id = countQuestion.value;
            response.data.title = "Questão " + countQuestion.value;
            response.data.file = "";
            Examdata.value.question.push({
              question: response.data,
            });

            var answered = response.data.options.filter((option) => {
              return option.checks_for_student;
            });
            if (answered != null) {
              quantityQuestionAnswered++;
            }
            countQuestion.value = countQuestion.value + 1;
          } else if (response.status == 201) {
            await finishExam();
          }
          isAllQuestionsAnswered.value =
            quantityQuestionAnswered == Examdata.value.number_questions
              ? true
              : false;
          showQuestions();
        })
        .catch((error) => {
          console.log(error);
          Alerts.alertOf("Não foi possivel iniciar o teste", "red-9");
        });

      loading.value = false;
    }

    async function _answerQuiz(index) {
      let answer_id = Examdata.value.question[index].question.answer_id;
      let isAnswered = Examdata.value.question[index].question.isAnswered;
      let data = {
        answers: [],
      };

      let options = Examdata.value.question[index].question.options.filter(
        (option) => {
          return option.checks_for_student;
        }
      );

      options.forEach((option) => {
        data.answers.push(parseInt(option.questionOptionId));
      });
      await postDataServices
        .lightningTestAnswer(ligthingTestId, answer_id, data)
        .then(async (response) => {
          if (response.status == 201) {
            if (response.data == "Evaluation finalizado!") {
              await finishExam();
              return;
            }
          }

          response.data.id = countQuestion.value;
          response.data.title = "Questão " + countQuestion.value;
          response.data.file = "";
          Examdata.value.question.push({
            question: response.data,
          });

          var answered = response.data.options.filter((option) => {
            return option.checks_for_student;
          });
          countQuestion.value = countQuestion.value + 1;
        })
        .catch(async (response) => {
          if (response.status == 201) {
            if (response.data == "Evaluation finalizado!") {
              await finishExam();
            }
          }
        });
      loading.value = false;
    }

    // function onEventFilePicked(event, question) {
    //   const files = event.target.files;
    //   const image = files[0];
    //   const filename = files[0].name;

    //   if (filename.lastIndexOf(".") <= 0) {
    //     return alert("Por favor adicione um arquivo válido");
    //   }

    //   const fileReader = new FileReader();
    //   fileReader.addEventListener("load", (event) => {
    //     //fileReader.result
    //   });

    //   Examdata.value.question[question].question.file = files[0];

    //   fileReader.readAsDataURL(files[0]);
    // }

    const sendEventRemoveImage = () => {
      imageUrl.value = "";
      refFileInput.value = null;
      file.value = "";
    };

    function handleDialog(type) {
      if (type == "orientation") {
        isOrientation.value = true;
        isDescription.value = false;
      } else {
        isOrientation.value = false;
        isDescription.value = true;
      }
      isShowOrientation.value = true;
    }

    async function handleExam() {
      loading.value = true;
      await _startQuiz();
      loading.value = false;
    }

    function handleFinishExam() {
      isShowOrientation.value = true;
      isEndExam.value = true;
    }

    async function finishExam() {
      await postDataServices
        .lightningTestScore(ligthingTestId).then((response) => {
          score.value = response.data;

          isShowOrientation.value = false;
          isShowConcluded.value = true;
          isEndExam.value = true;
        })
        .catch((err) => {
          $q.notify({
            message: "Erro ao calcular a pontuacao do usuario",
            color: "red-9",
            position: "top",
            timeout: 3000,
          });
        });

        // score.value = score.data;

        // isShowOrientation.value = false;
        // isShowConcluded.value = true;
        // isEndExam.value = true;
    }

    function showQuestions() {
      isStarted.value = true;
      isShowOrientation.value = false;
      isDescription.value = false;
      isOrientation.value = false;
      isEndExam.value = false;

      if (
        checkedQuestions.value.length == Examdata.value.question.length &&
        isStarted.value == true
      ) {
        isAllQuestionsAnswered.value = ref(true);
      }
    }

    function selectAnswer(question, value) {
      Examdata.value.question[question].question.answer = value;
      Examdata.value.question[question].question.options.filter((option) => {
        if (option.questionOptionId != value) {
          option.checks_for_student = false;
        } else {
          option.checks_for_student = true;
        }
      });
    }

    function selectMultiAnswer(question) {
      let options = Examdata.value.question[question].question.options.filter(
        (option) => {
          return option.checks_for_student;
        }
      );
      let values = "";
      options.forEach((option) => {
        values += option.questionOptionId + ",";
      });
      values = values.substring(0, values.length - 1);

      Examdata.value.question[question].question.answer = values;
    }

    async function confirmAnswer(event, question) {
      event.preventDefault();
      loading.value = true;
      if (Examdata.value.question[question].question.answer == null) {
        $q.notify({
          message: "Marque um alternativa",
          color: "red-9",
          position: "top",
          timeout: 3000,
        });
      } else {
        await _answerQuiz(question);
        let awnswers = Examdata.value.question.filter((item) => {
          return item.question.answer != null;
        });

        if (Examdata.value.number_questions == Examdata.value.question.length) {
          isAllQuestionsAnswered.value = ref(true);
          //router.push({name: 'training-page', query: { courseId: courseId, trainingId: id }});
        }

        localStorage.setItem("LightingAnswers", JSON.stringify(awnswers));
        checkedQuestions.value.push(
          Examdata.value.question[question].question.id
        );
      }
      loading.value = false;
    }

    function _countDowWatch() {
      if (isShowConcluded.value == true) {
        return;
      }
      let countdown = setInterval(() => {
        if (Examdata.value.timer >= 0) {
          _convertCountDown(Examdata.value.timer);
          Examdata.value.timer++;
        } else {
          _convertCountDown(0);
          // clearInterval(countdown);
          isShowOrientation.value = true;
          isEndExam.value = true;
        }
      }, 1000);
    }

    function _convertCountDown(value) {
      // if(value > 0){
      //   let h = Math.floor(value / 3600);
      //   let m = Math.floor((value % 3600) / 60);
      //   let s = Math.floor((value % 3600) % 60);
      //   countDownDisplay.value[0] = h >= 10 ? h : "0" + h;
      //   countDownDisplay.value[1] = m >= 10 ? m : "0" + m;
      //   countDownDisplay.value[2] = s >= 10 ? s : "0" + s;
      // }else{
      //   countDownDisplay.value[0] = "00"
      //   countDownDisplay.value[1] = "00"
      //   countDownDisplay.value[2] = "00"
      // }
      /* let minuts = Math.floor(value / 60);
      let seconds = value % 60;
      countDownDisplay.value[0] = minuts >= 10 ? minuts : "0" + minuts;
      countDownDisplay.value[1] = seconds >= 10 ?  Math.Round(seconds) : "0" + Math.Round(seconds);*/
    }

    function _getSaveData() {
      let answers = JSON.parse(localStorage.getItem("answers"));

      if (answers != null) {
        Examdata.value.question.map((item) => {
          answers.map((answer) => {
            if (answer.question.id == item.question.id) {
              checkedQuestions.value.push(item.question.id);
              item.question.answer = answer.question.answer;
              item.question.question = answer.question.question;
            }
          });
        });
      }
    }

    return {
      //----IMAGES
      teacherIcon,

      //----CONSTANTS
      // courseId,
      // trainingId,
      // postId,
      score,

      //----VARIABLES
      isShowOrientation,
      isShowConcluded,
      isOrientation,
      isDescription,
      isStarted,
      isShowScore,
      //countDownDisplay,
      isAllQuestionsAnswered,
      isEndExam,
      refFileInput,
      refTabs,
      checkedQuestions,
      Examdata,
      loading,

      //----FUNCTION
      handleDialog,
      //sendEventRemoveImage,
      //onEventFilePicked,
      handleExam,
      showQuestions,
      handleFinishExam,
      finishExam,
      selectAnswer,
      answerIdInPage,
      confirmAnswer,
      selectMultiAnswer,
    };
  },
};
</script>

<style lang="scss" scoped>
.exam-layout {
  display: flex;
  flex-direction: column;

  @media (min-width: 800px) {
    flex-direction: column;
  }
}

.exam-content {
  width: 99%;
  height: 69%;
  display: flex;
  //flex: 4;
  flex-direction: column;
  overflow: auto;
  .questioncontent {
    display: flex;
    flex-direction: column;
    /* height: 80vh; */
    overflow: auto;
    background: #f7f7f7;
    box-shadow: 0px 0.5px 1.75px rgba(0, 0, 0, 0.039),
      0px 1.85px 6.25px rgba(0, 0, 0, 0.19);
    border-radius: 20px;

    h3 {
      margin: 0;
      font-style: normal;
      font-weight: 600;
      font-size: 2rem;
      line-height: 16px;
      color: #000000;
    }

    p {
      margin: 2% 0;
      font-style: normal;
      font-weight: 500;
      font-size: 0.75rem;
      line-height: 15px;
      display: flex;
      align-items: center;
      color: #00000073;
    }

    .anexo-btn {
      margin-bottom: 1%;

      background: #fdfdfd;
      color: #fe5268;
      border: 1px solid #fe5268;
      box-sizing: border-box;
      box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.039),
        0px 0.5px 1.5px rgba(0, 0, 0, 0.19);
      border-radius: 50px;
      cursor: pointer;
      @media (min-width: 800px) {
        margin-bottom: 0%;
      }
    }
  }
  .card-question {
    display: flex;
    flex-direction: column;
    /* min-height: 60%; */
  }

  .card-option {
    background: #fdfdfd;
    margin-bottom: 1.5%;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.039),
      0px 0.5px 1.5px rgba(0, 0, 0, 0.19);
    border-radius: 12px;
    cursor: pointer;

    h6 {
      margin: 0 1rem;
      font-style: normal;
      font-weight: normal;
      font-size: 1.68rem;
      line-height: 33px;
      display: flex;
      align-items: center;
      color: #000000;
    }
    p {
      margin: 0;
      font-style: normal;
      font-weight: 500;
      font-size: 0.75rem;
      line-height: 15px;
      display: flex;
      align-items: center;
      color: #00000073;
    }
  }

  .card-option-selected {
    border: 2px solid #489b88;
    margin-bottom: 1.5%;
    box-sizing: border-box;
    box-shadow: 0px 0.5px 1.75px rgba(0, 0, 0, 0.039),
      0px 1.85px 6.25px rgba(0, 0, 0, 0.19);
    border-radius: 12px;
    cursor: pointer;

    h6 {
      margin: 0 1rem;
      font-style: normal;
      font-weight: normal;
      font-size: 1.68rem;
      line-height: 33px;
      display: flex;
      align-items: center;
      color: #489b88;
    }
    p {
      margin: 0;
      font-style: normal;
      font-weight: 600;
      font-size: 0.75rem;
      line-height: 15px;
      display: flex;
      align-items: center;
      color: #000000;
    }
  }
}
</style>
