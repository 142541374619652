<template>
  <div class="flex column items-end justify-evenly full-height q-px-md">
    <div v-if="isEndExam" class="full-width q-pa-sm">
      <h6>Tem certeza que deseja finalizar a avaliação?</h6>
    </div>
    <div v-if="!isStarted" class="flex items-ceter justify-center full-width q-pa-sm">
      <q-btn
        color="default-pink"
        text-color="white"
        no-caps
        unelevated
        dense
        label="Voltar"
        v-close-popup
        :to="{
          name: 'lighting-test-list',
          query: {},
        }"
      />
      <q-btn
        class="q-ml-md"
        color="default-pink"
        text-color="white"
        no-caps
        unelevated
        dense
        label="Iniciar avaliação"
        v-close-popup
        @click="()=>$emit('handleExam')"
      />
    </div>
    <div v-else>
      <q-btn
        v-if="isAllQuestionsAnswered"
        class="q-ml-md"
        color="default-pink"
        text-color="white"
        no-caps
        unelevated
        dense
        label="Encerrar Avaliação"
        @click="()=>$emit('finishExam')"
        v-close-popup
      />
    </div>
  </div>
</template>


<script>
export default {
  name: "OrientationExam",
  props:{
    isEndExam:Boolean,
    isStarted:Boolean,
    isAllQuestionsAnswered:Boolean,
  },
  emits:['handleExam', 'finishExam']
};
</script>

<style></style>

