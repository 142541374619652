<template>
  <aside class="exam-information q-pa-md">
    <div class="title">
      <q-btn
        dense
        flat
        icon="chevron_left"
        :to="{
          name: 'lighting-test-list',
          query: {},
        }"
      />
      <div class="icon-exam q-mr-sm">
        <BadgeType iconBadge="book" typeBadge="Exam" />
      </div>
      <h4>Avaliação</h4>
    </div>
    <div class="exam-information-content q-mt-md">
      <div class="exam-about q-pa-sm q-mr-md">
        <p>{{ examdata.title }}</p>
        <small class="flex items-center q-mt-sm">
          <!-- <span>
            <q-img width="20px" height="16px" :src="teacherIcon" />
          </span> -->
          <Teacher class="q-mr-sm" color="var(--q-secundary)" />
          {{ examdata.tutor }}
        </small>
        <!-- <p>{{examdata.points}} pontos</p> -->
      </div>
      <div class="exam-orientation column q-pa-sm">
        <h6>Orientações:</h6>
        <p class="orientation-items">
          {{ examdata.orientations }}
        </p>
      </div>
      <div class="action-btn">
        <!-- <q-btn
          :disable="!isAllQuestionsAnswered"
          class="btn-finish"
          unelevated
          label="Entregar prova"
          @click="handleFinishExam"
        /> -->
        <q-btn-dropdown
          class="btn-dropdown"
          dropdown-icon="menu"
          flat
          color="default-pink"
        >
          <q-list>
            <q-item
              clickable
              v-close-popup
              @click="handleDialog('description')"
            >
              <q-item-section>
                <q-item-label>Descrição</q-item-label>
              </q-item-section>
            </q-item>

            <q-item
              clickable
              v-close-popup
              @click="handleDialog('orientation')"
            >
              <q-item-section>
                <q-item-label>Orientações</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>
      </div>
    </div>
  </aside>
</template>

<script>
//----IMAGES
import Teacher from '@/assets/icons/custom/Teacher.vue'

//----COMPONENTS
import BadgeType from "@/components/BadgeType.vue";

export default {
  name: "LightingAside",
  components: {
    BadgeType,

    //----IMAGES
    Teacher
  },
  props: {
    examdata: Object,
  },
  setup(props) {
    return {
    };
  },
};
</script>

<style lang="scss" scoped>
.exam-information {
  display: flex;
  flex-direction: column;
  // justify-content: center;
  // align-content: flex-start;

  .title,
  .exam-about,
  .exam-orientation {
    display: none;
  }

  @media (min-width: 800px) {
    .title,
    .exam-about,
    .exam-orientation {
      display: flex;
      flex: 1;
    }
  }
}

.title {
  .icon-exam {
    width: 45px;
    height: 45px;
  }
  h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 2rem;
  }
}
.exam-information-content {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.exam-about {
  display: flex;
  flex-direction: column;
  flex: 1;

  border: 1px solid #489b88;
  border-radius: 20px;
  /* padding: 1% 2%; */
  p {
    font-weight: 600;
    font-size: 1.1rem;
    line-height: 22px;
    margin: 0;
    display: flex;
    align-items: center;
    color: #686868;
  }
  small {
    font-weight: 500;
    font-size: 0.81rem;
    line-height: 16px;
    color: #00000073;
  }
}
.exam-orientation {
  display: flex;
  flex-direction: column;
  flex: 1;

  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  box-shadow: 0px 0.25px 1px rgba(0, 0, 0, 0.039),
    0px 0.85px 3px rgba(0, 0, 0, 0.19);
  border-radius: 20px;

  h6 {
    font-style: normal;
    font-weight: bold;
    font-size: 0.87rem;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #686868;
  }

  .orientation-items {
    margin: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: #686868;

    border-bottom: 1px solid #e5e5e5;
    padding: 2%;
  }
}

.action-btn {
  display: flex;
  justify-content: space-between;

  @media (min-width: 800px) {
    justify-content: center;
  }

  .btn-finish {
    background: #54aa95;
    color: white;
    box-shadow: 0px 0.25px 1px rgba(0, 0, 0, 0.039),
      0px 0.85px 3px rgba(0, 0, 0, 0.19);
    border-radius: 8px;
  }
  .btn-dropdown {
    display: flex;

    @media (min-width: 800px) {
      display: none;
    }
  }
}
</style>
