<template>
  <div 
    class="avatar-bg-notification flex items-center justify-center"
    :class="[getColorBgBadge(typeBadge)]"
  >
    <div 
      class="avatar-notification flex items-center justify-center"
    >
      <q-img :src="require(`@/assets/icons/${iconBadge}.svg`)" :width="isDoc? '50%' : '70%'" fit spinner-color="white" />
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import ColorsClass from "@/utils/styles/colors/getColors.js";

export default {
  name: "BadgeType",
  props:{
    typeBadge:{
      type:String,
      default:"add"
    },
    iconBadge:{
      type:String,
      default:"pen"
    },
    isDoc:{
      type:Boolean,
      default: false
    }
  },

  setup() {
    //-----CONSTANTE
    const Colors = new ColorsClass();
    
    let item = ref('Quiz')
    function getColorBgBadge(name) {
      return  Colors.getColorBgBadge(name);
    }  
    return {
      item,
      getColorBgBadge,
    };
  },
};
</script>

<style lang="scss" scoped>
.avatar-bg-notification {
  width: 100%;
  height: 100%;
  margin: 0 3%;
  padding: 6px;
  overflow: hidden;
  border-radius: 100%;
}
.avatar-notification {
  width: 100%;
  height: 100%;
  // margin: 13%;
  /* padding: 10%; */
  border-radius: 80%;

  background: linear-gradient(
    228.65deg,
    rgba(255, 255, 255, 0.5) 14.78%,
    rgba(255, 255, 255, 0.2) 84.91%
  );
}

.item-bg-pink {
  background: var(--q-primary, #fe4e64);
}
.item-bg-yellow {
  background: #D49A41;
}
.item-bg-purple {
  background: linear-gradient(45.37deg, #A55AB3 15.61%, #DA99E7 84.86%);
}
.item-bg-blue {
 background: linear-gradient(45.37deg, #3F8AB3 15.61%, #6EB2D7 84.86%);
}
.item-bg-green {
  background: linear-gradient(45.37deg, #77aa3d 15.61%, #aad67a 84.86%);
}
.item-bg-depp-green{
  background: linear-gradient(45.37deg, #439380 15.61%, #62BDA8 84.86%);
}

</style>
